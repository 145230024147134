import React from "react"

const PrivacyPolicyDocument = () => {
  return (
    <div className="DocumentBlock">
      <p>
        <i>Date of Last Revision: February 07, 2021</i>
      </p>
      <p>
        This privacy policy governs processing (e.g. obtaining, recording,
        storing or disclosing) of information collected from users (
        <b>„you”, „User“</b>) of the mothership.cx website (<b>„Site“</b>) or
        any of Mothership Foundation OÜ’s (hereinafter{" "}
        <b>„Mothership“, „we“, „us“</b>) customers or cooperation partners (or
        their representatives) by us.
      </p>
      <p>
        Mothership Foundation OÜ is a company registered in the Republic of
        Estonia with the registration number 14284254.
      </p>
      <p>
        For further information on how your information is used, how we maintain
        the security of your information, and your rights to access the
        information we hold on you, please contact our Data Protection Officer
        at <b>gdpr@mothership.cx.</b>
      </p>
      <h2>Information we may collect </h2>
      <ol>
        <li>
          Personal identification information:
          <ol>
            <li>
              We may collect personal identification information from you in a
              variety of ways, including, but not limited to, when you register
              on, visit or use our Site, fill out a form, and in connection with
              other activities, services, features or resources we make
              available to you on our Site.{" "}
            </li>
            <li>
              We may collect and process your personal details (e.g. your and
              your representative’s first name, last name, country of residence,
              place and date of birth, personal identification code, a copy of
              identification document and whether you are politically exposed
              person), contact details (e.g. e-mail address and telegram
              handle), location details and financial details (e.g. public
              wallet addresses and the transaction therein, Crowdsale
              contribution ID and amount, transactions and orders, source of
              funds, and ultimate beneficiaries).
            </li>
            <li>
              We will collect personal identification information from you if
              you voluntarily submit such information to us. If you register at
              our Site, then we may verify and collect the information from
              public sources and third party service providers. Users can always
              refuse to supply personally identification information, except
              that it may prevent them from engaging in certain Site-related
              activities. Thus, in some cases, we will be unable to render
              services to you if you do not provide us personal identification
              information due to the requirements of anti-money laundering laws
              and other legal acts.{" "}
            </li>
          </ol>
        </li>
        <li>
          Non-personal identification information: we may collect non-personal
          identification information about you whenever you interact with our
          Site. Non-personal identification information may include browser name
          and technical information about the your means of connection to our
          Site, such as details of the device used, IP-address, location, date
          and time of connection, operating system and Internet service
          providers utilised, and other similar information.{" "}
        </li>
        <li>
          Web browser cookies: our Site may use "cookies" to enhance User
          experience. User’s web browser places cookies on their hard drive for
          record-keeping purposes, and sometimes to track information about
          them. You may choose to set their web browser to refuse cookies, or to
          be alerted when cookies are being sent. If you do so, note that some
          parts of the Site may not function properly. Learn more about the way
          our Site may use cookies in our Cookie Policy.{" "}
        </li>
      </ol>
      <h2>How we use the collected information </h2>
      <p>Mothership may collect and use your personal information to:</p>
      <ol>
        <li>
          Provide customer services and manage legal relationships arising from
          customer contracts (Art 6(1)(b) of the General Data Protection
          Regulation (<b>„GDPR“</b>));{" "}
        </li>
        <li>
          Offer and inform you about the best possible options suitable for your
          needs (e.g. how to proceed with your stake in MSP Tokens; our interest
          is to make sure we keep up with the wishes of the tokenholders and
          enable you to utilise the MSP Tokens as well as possible) (Art 6(1)(f)
          of the GDPR); and
        </li>
        <li>
          Comply with anti-money laundering and other legal obligations (Art
          6(1)(c) of the GDPR).
        </li>
      </ol>
      <p>
        We will ask your separate consent before using the collected information
        for other purposes, unless other legal ground for processing applies.{" "}
      </p>
      <p>
        We store the collected information for as long as it is necessary for
        the purposes the information was collected. The information associated
        with your account will be kept until your account is deleted, unless we
        no longer need the data to provide the services or we need to store your
        information for other legitimate purposes listed above. However, we
        generally store information on your transactions (and any data
        associated to it) for 5 years after the deletion of account.{" "}
      </p>
      <h2>Marketing</h2>
      <p>
        We would like to send you information about products and services of
        ours if you have consented to receive marketing information. In such
        case, your consent will be the legal basis for processing your data.{" "}
      </p>
      <p>
        You have a right at any time to stop us from contacting you for
        marketing purposes by opting out and notifying us at
        <b>hello@mothership.cx.</b>
      </p>
      <h2>Authorised processors and third party disclosure </h2>
      <p>
        Mothership may use other third party authorized processors such as
        technical service providers, e-mail service providers, communication
        agencies, hosting service providers, identification service providers,
        error reporting service providers and other IT service providers to
        process your data. For example your data may be stored in servers hosted
        by third party cloud service providers such as Google, which may be
        located anywhere around the world. The data stored in such servers is
        secured and cannot be accessed by any third parties. Please contact
        Mothership to receive and updated list of such authorized processors.
        Any such authorized processor shall be obliged to adhere to the
        restrictions set forth in this privacy policy.{" "}
      </p>
      <p>
        We may additionally disclose your personal information to other third
        parties if it is necessary for the purposes of providing, maintaining,
        reviewing or improving the services. We will oblige any third party
        processing your personal information, to carry out the processing in
        accordance with this privacy policy. We sometimes need to share personal
        information we process with third parties. Where necessary or required,
        we share information with:{" "}
      </p>
      <ol>
        <li>
          Representatives of the person whose personal data we are processing;{" "}
        </li>
        <li>Financial institutions; </li>
        <li>Debt collection and tracing agencies; </li>
        <li>Suppliers and service providers; </li>
        <li>Government, law enforcement and prosecuting authorities; and</li>
        <li>Courts and tribunals. </li>
      </ol>
      <h2>How we protect your information</h2>
      <p>
        We adopt appropriate data collection, storage and processing practices
        and security measures to protect from unauthorised access, alteration,
        disclosure or destruction of your personal information, user
        credentials, transaction information and data stored on our Site. As
        soon as we receive your private information, we implement strict
        security measures and procedures to avoid unauthorised access from any
        third party. However, transmission of such data over the Internet using
        personal computers or mobile devices is not completely safe and,
        therefore, we cannot guarantee absolute security of all information
        during the transit to our platform. Any transmission of such information
        and documents is at your own risk.
      </p>
      <h2>Storage and transfers</h2>
      <p>
        The information that we collect from you may be transferred to and
        stored at a destination outside the European Economic Area, in
        particular if you are based outside the European Economic Area. Your
        information may also be processed by staff and contractors operating
        outside the European Economic Area. These countries may be deemed by the
        Commission to provide adequate level of protection. We also apply
        additional safeguards to ensure the protection of your data (e.g.
        conclude standard data protection clauses as adopted by the Commission,
        Art 46 (2) (c)). In any case, all transfers to countries outside the
        European Economic Area will be in full compliance with all aspects of
        the GDPR.{" "}
      </p>
      <h2>Third party links and services</h2>
      <p>
        Please remember that when you use a hyperlink to go from our Site to
        another website or you request a service from a third party, our privacy
        policy no longer applies. Your browsing and interaction on any other
        website or your dealings with any other third party service provider, is
        subject to that website’s or third party service provider’s own rules
        and policies. We do not monitor, control, or endorse the information
        collection or privacy practices of any third parties. We encourage you
        to become familiar with the privacy practices of every website you visit
        or third party service provider that you deal with and to contact them
        if you have any questions about their respective privacy policies and
        practices. This privacy policy applies solely to information collected
        by us through our Site or services and does not apply to these third
        party websites and third party service providers.{" "}
      </p>
      <h2>Your rights</h2>
      <p>
        You have a right to access your personal identification information. You
        may update or delete such information at any time on your own
        discretion. We will not keep outdated or deleted information, unless we
        have to do so for legitimate business purposes or in accordance with
        legislative or regulatory requirements.{" "}
      </p>
      <p>
        You also have the right to request a copy of information that we hold
        about you, request to restrict the processing, object to processing as
        well to receive your personal information in a structured, commonly used
        and machine-readable format, and to have us transfer your personal
        information directly to another “controller”, where technically
        feasible, unless exercise of this right adversely affects the rights and
        freedoms of others. Please submit a written application at
        <b>hello@mothership.cx</b>. If any of the information that we hold about
        you is incorrect, you can change it by contacting as at
        <b>hello@mothership.cx.</b>
      </p>
      <p>
        If we process your data based on your consent, then you have a right at
        any time to withdraw your consent by notifying us at
        <b>hello@mothership.cx.</b>
      </p>
      <p>
        If you have a concern about the way we handle your personal information
        you have a right to lodge a complaint to our data protection officer at
        <b>gpdr@mothership.cx</b> or the supervisory authority or turn to the
        court. For more information on the process please visit the website of
        the Estonian Data Protection Inspectorate at
        http://www.aki.ee/en/what-can-people-demand-regarding-their-data.{" "}
      </p>
      <h2>Notifications</h2>
      <p>
        Mothership has the discretion to update this privacy policy at any time.
        When we do so, we may post a notification on the main page of our Site
        and revise the updated date at the top of this page. We encourage you to
        frequently check this page for any changes to stay informed about how we
        are helping to protect the personal information we collect. You
        acknowledge and agree that it is your responsibility to review this
        privacy policy periodically and become aware of modifications.{" "}
      </p>
      <h2>Changes to this privacy policy</h2>
      <p>
        By using this Site, you signal your acceptance of this privacy policy.
        If you do not agree to this privacy policy, please do not use our Site
        or other services. Your continued use of the Site or services following
        the posting of changes to this policy will be deemed as your acceptance
        of those changes.{" "}
      </p>
      <h1>Cookie Policy</h1>
      <p>
        A cookie is a very small text document or piece of code, which often
        includes an anonymous unique identifier. When you visit a website or use
        a mobile application, a computer asks your computer or mobile device for
        permission to store this file (or similar technologies such as web
        beacons) on your computer or mobile device and access information from
        it. Information gathered through cookies or similar technologies may
        include the date and time of visits and how you are using the Website.{" "}
      </p>
      <p>
        Cookies are used by us as part of this Site. Our Site also uses third
        party cookies for analytics.{" "}
      </p>
      <p>
        By continuing to use our Site you are deemed to consent to our use of
        the cookies described in this Cookie Policy. If you do not consent to us
        using cookies then please read the next section.{" "}
      </p>
      <h2>Blocking our use of cookies</h2>
      <p>
        You can block the use of cookies by activating the relevant settings in
        your browser.{" "}
      </p>
      <h2>What happens when you block cookies</h2>
      <p>
        In order to use some parts of our Site your web browser will need to
        accept cookies. If you choose to withhold consent, or subsequently block
        cookies, some aspects of the Site may not work properly and you may not
        be able to access all or part of the Site.{" "}
      </p>
      <h2>What we do with cookies </h2>
      <p>
        We use session cookies to allow the Site to uniquely identify your
        browsing session on the Site, which allows us to coordinate this
        information with data from the Site server. Such cookies are stored
        temporarily during a browsing session and are deleted from the user’s
        device when the browser is closed.{" "}
      </p>
      <p>
        We also use persistent cookies that may be saved on your computer for a
        fixed period and are not deleted when the browser is closed where we
        need to know who you are for more than one browsing session. For
        example, we use this type of cookie to store your preferences, so that
        they are remembered for the next visit, or to keep you logged in if you
        navigate away.{" "}
      </p>
      <p>
        We may also use cookies where they are essential for the operation of
        the Site, for example to remember that you are signed in to the Site
        during your session.{" "}
      </p>
      <p>
        We use “analytics” cookies. These, along with other information, allow
        us to calculate the aggregate number of people using the Site and which
        features of the Site are most popular.{" "}
      </p>
      <p>
        We use this information to improve the Site. We do not generally store
        any personal data that may be provided to us in a cookie.{" "}
      </p>
      <h2>We use the following types of cookies or similar technologies: </h2>
      <ol>
        <li>
          Google Analytics (Third Party Cookie) - These cookies enable us to
          store information such as the time you visit our Site, whether you
          have visited before and the Site that you visited prior to visiting
          our Site. For more information about Google Analytics please see:
          http://www.google.com/intl/en/analytics/privacyoverview.html.{" "}
        </li>
        <li>
          Intercom’s essential, analytics and customization cookies and
          messenger cookies. For more information see:
          https://www.intercom.com/legal/cookie-policy.{" "}
        </li>
        <li>
          Track URL - This cookie informs us which webpage you visited
          immediately before coming to our Website. It does not collect any
          personal data or send personal data any third party.{" "}
        </li>
        <li>
          Local storage - Local storage is an industry-standard technology that
          allows a website or mobile app to store and retrieve data on an
          individual’s computer, mobile phone or other device.
        </li>
      </ol>
    </div>
  )
}

export default PrivacyPolicyDocument
