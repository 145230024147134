import React, { useState } from "react"
import { navigate } from "gatsby"
import { withStyles } from "@material-ui/core/styles"
import * as Sentry from "@sentry/gatsby"
import Checkbox from "@material-ui/core/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Alert from "@material-ui/lab/Alert"
import { functions } from "../../configs/firebase"
import { reportToSentry } from ".."
import { statuses as authStatuses } from "../../hooks/auth"
import { useAppState } from "../../components/provider"
import Layout from "../../components/layout"
import LoaderBlock from "../../components/loader"
import SEO from "../../components/seo"
import PrivacyPolicyDocument from "../../components/docs/privacy-policy"

export const formStatuses = {
  initial: "initial",
  processing: "processing",
  success: "success",
  failed: "failed",
}

export const DefaultCheckbox = withStyles({
  root: {
    color: "#50d2dc",
    "&$checked": {
      color: "#2ac4d0",
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />)

const Form = ({ onSubmit, ppAcceptedAt, status, isAvailable }) => {
  const [value, setPrivacyPolicy] = useState(!!ppAcceptedAt)

  const onChange = event => {
    setPrivacyPolicy(event.target.checked)
  }

  return (
    <form onSubmit={onSubmit}>
      <div className="CheckboxLabel">
        {status === formStatuses.failed && (
          <Alert severity="error" style={{ marginBottom: "1rem" }}>
            Something went wrong&hellip; Please try submitting it again later.
          </Alert>
        )}
        {ppAcceptedAt ? (
          <p className="Text">
            Privacy Policy accepted at{" "}
            <strong>{ppAcceptedAt.toDate().toUTCString()}</strong>
          </p>
        ) : !isAvailable ? null : (
          <FormControlLabel
            control={
              <DefaultCheckbox
                name="privacyPolicy"
                onChange={onChange}
                checked={value}
                disabled={status === formStatuses.processing}
              />
            }
            label="I have read and understood the Privacy Policy"
          />
        )}
      </div>
      {!isAvailable ? null : (
        <button
          className="Btn primary"
          disabled={!value || status === formStatuses.processing}
        >
          Continue
        </button>
      )}
    </form>
  )
}

const PrivacyPolicy = () => {
  const [formStatus, setFormStatus] = useState(formStatuses.initial)
  const {
    auth: { status: authStatus, user, profile = {} },
  } = useAppState()

  const buyback = profile.buyback || {}

  const onSubmit = event => {
    event.preventDefault()
    const postPolicy = functions.httpsCallable("buyback-acceptPP")
    setFormStatus(formStatuses.processing)
    postPolicy()
      .then(() => {
        setFormStatus(formStatuses.success)
        navigate("/buyback/terms-of-service")
      })
      .catch(err => {
        setFormStatus(formStatuses.failed)
        reportToSentry(err, {
          user: { id: user.uid },
        })
      })
  }

  if (user === null && authStatus === authStatuses.initial) {
    navigate("/")
    return null
  }

  return (
    <Layout>
      <SEO title="Stakeholder Profile" />
      <Sentry.ErrorBoundary
        beforeCapture={scope => {
          scope.setUser({ id: profile.uid })
        }}
      >
        <div className="Section">
          <div className="PanelContainer">
            <h1 className="Title">Privacy Policy</h1>
            <section className="Panel textFormat">
              <PrivacyPolicyDocument />
              {buyback ? (
                <Form
                  onSubmit={onSubmit}
                  ppAcceptedAt={buyback.ppAcceptedAt}
                  status={formStatus}
                  isAvailable={buyback.isAvailable}
                />
              ) : (
                <LoaderBlock isLoading />
              )}
            </section>
          </div>
        </div>
      </Sentry.ErrorBoundary>
    </Layout>
  )
}

export default PrivacyPolicy
